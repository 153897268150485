// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#hotmart-cookie_alert-component[dir="rtl"] .cookie-policy-footer button:last-of-type {
  margin-left: 0;
  margin-right: var(--spacer-4);
}

#hotmart-cookie-modal-configuration[dir="rtl"] hot-list-group>hot-list-group-item {
  padding-right: var(--spacer-4);
}

#hotmart-cookie-modal-configuration[dir="rtl"] hot-list-group-item .hot-collapse__item {
  margin-right: var(--spacer-4);
}

#hotmart-cookie-modal-configuration[dir="rtl"] hot-list-group-item div[slot="collapsed"] {
  margin-right: 0;
}

#hotmart-cookie-modal-configuration[dir="rtl"] hot-list-group-item label.hot-form__label {
  padding-right: var(--spacer-2);
}

#hotmart-cookie-modal-configuration[dir="rtl"] hot-list-group-item label.hot-form__label::before {
  left: unset;
  right: calc(var(--spacer-7) * -1);
}

#hotmart-cookie-modal-configuration[dir="rtl"] hot-list-group-item label.hot-form__label::after {
  left: unset;
  right: calc(var(--spacer-7) * -1);
  transition: 0.2s;
}

#hotmart-cookie-modal-configuration[dir="rtl"] hot-list-group-item .hot-form__input:checked ~ label.hot-form__label::after {
  left: unset;
  right: calc(var(--spacer-5) * -1);
}

@media all and (max-width: 576px) {
  #hotmart-cookie_alert-component[dir="rtl"] .cookie-policy-footer button:last-of-type {
    margin-right: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/cookie-banner/styles/page-rtl.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,iCAAiC;AACnC;;AAEA;EACE,WAAW;EACX,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iCAAiC;AACnC;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["#hotmart-cookie_alert-component[dir=\"rtl\"] .cookie-policy-footer button:last-of-type {\n  margin-left: 0;\n  margin-right: var(--spacer-4);\n}\n\n#hotmart-cookie-modal-configuration[dir=\"rtl\"] hot-list-group>hot-list-group-item {\n  padding-right: var(--spacer-4);\n}\n\n#hotmart-cookie-modal-configuration[dir=\"rtl\"] hot-list-group-item .hot-collapse__item {\n  margin-right: var(--spacer-4);\n}\n\n#hotmart-cookie-modal-configuration[dir=\"rtl\"] hot-list-group-item div[slot=\"collapsed\"] {\n  margin-right: 0;\n}\n\n#hotmart-cookie-modal-configuration[dir=\"rtl\"] hot-list-group-item label.hot-form__label {\n  padding-right: var(--spacer-2);\n}\n\n#hotmart-cookie-modal-configuration[dir=\"rtl\"] hot-list-group-item label.hot-form__label::before {\n  left: unset;\n  right: calc(var(--spacer-7) * -1);\n}\n\n#hotmart-cookie-modal-configuration[dir=\"rtl\"] hot-list-group-item label.hot-form__label::after {\n  left: unset;\n  right: calc(var(--spacer-7) * -1);\n  transition: 0.2s;\n}\n\n#hotmart-cookie-modal-configuration[dir=\"rtl\"] hot-list-group-item .hot-form__input:checked ~ label.hot-form__label::after {\n  left: unset;\n  right: calc(var(--spacer-5) * -1);\n}\n\n@media all and (max-width: 576px) {\n  #hotmart-cookie_alert-component[dir=\"rtl\"] .cookie-policy-footer button:last-of-type {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
