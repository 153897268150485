// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    --spacer-1: .25rem;
    --spacer-2: .5rem;
    --spacer-3: .75rem;
    --spacer-4: 1rem;
    --spacer-5: 1.5rem;
    --spacer-6: 2rem;
    --spacer-7: 2.5rem;
    --spacer-8: 3rem;
    --spacer-9: 3.5rem;
    --spacer-10: 4rem;
    --spacer-11: 4.5rem;
    --spacer-12: 5rem;
}


._mb-0 {
  margin-bottom: 0 !important;
}

._p-0 {
    padding: 0 !important
}

._mb-3, ._my-3 {
    margin-bottom: .75rem !important;
    margin-bottom: var(--spacer-3) !important
}

._p-3 {
    padding: .75rem !important;
    padding: var(--spacer-3) !important
}

._mr-4, ._mx-4 {
    margin-right: 1rem !important;
    margin-right: var(--spacer-4) !important
}

._p-5 {
    padding: 1.5rem !important;
    padding: var(--spacer-5) !important
}

._pl-5, ._px-5 {
    padding-left: 1.5rem !important;
    padding-left: var(--spacer-5) !important
}

._mb-5, ._my-5 {
    margin-bottom: 1.5rem !important;
    margin-bottom: var(--spacer-5) !important
}

._pr-5, ._px-5 {
    padding-right: 1.5rem !important;
    padding-right: var(--spacer-5) !important
}

._mr-auto, ._mx-auto {
    margin-right: auto !important
}

._ml-auto, ._mx-auto {
    margin-left: auto !important
}
`, "",{"version":3,"sources":["webpack://./src/cookie-banner/styles/spacing.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;AACrB;;;AAGA;EACE,2BAA2B;AAC7B;;AAEA;IACI;AACJ;;AAEA;IACI,gCAAgC;IAChC;AACJ;;AAEA;IACI,0BAA0B;IAC1B;AACJ;;AAEA;IACI,6BAA6B;IAC7B;AACJ;;AAEA;IACI,0BAA0B;IAC1B;AACJ;;AAEA;IACI,+BAA+B;IAC/B;AACJ;;AAEA;IACI,gCAAgC;IAChC;AACJ;;AAEA;IACI,gCAAgC;IAChC;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ","sourcesContent":[":host {\n    --spacer-1: .25rem;\n    --spacer-2: .5rem;\n    --spacer-3: .75rem;\n    --spacer-4: 1rem;\n    --spacer-5: 1.5rem;\n    --spacer-6: 2rem;\n    --spacer-7: 2.5rem;\n    --spacer-8: 3rem;\n    --spacer-9: 3.5rem;\n    --spacer-10: 4rem;\n    --spacer-11: 4.5rem;\n    --spacer-12: 5rem;\n}\n\n\n._mb-0 {\n  margin-bottom: 0 !important;\n}\n\n._p-0 {\n    padding: 0 !important\n}\n\n._mb-3, ._my-3 {\n    margin-bottom: .75rem !important;\n    margin-bottom: var(--spacer-3) !important\n}\n\n._p-3 {\n    padding: .75rem !important;\n    padding: var(--spacer-3) !important\n}\n\n._mr-4, ._mx-4 {\n    margin-right: 1rem !important;\n    margin-right: var(--spacer-4) !important\n}\n\n._p-5 {\n    padding: 1.5rem !important;\n    padding: var(--spacer-5) !important\n}\n\n._pl-5, ._px-5 {\n    padding-left: 1.5rem !important;\n    padding-left: var(--spacer-5) !important\n}\n\n._mb-5, ._my-5 {\n    margin-bottom: 1.5rem !important;\n    margin-bottom: var(--spacer-5) !important\n}\n\n._pr-5, ._px-5 {\n    padding-right: 1.5rem !important;\n    padding-right: var(--spacer-5) !important\n}\n\n._mr-auto, ._mx-auto {\n    margin-right: auto !important\n}\n\n._ml-auto, ._mx-auto {\n    margin-left: auto !important\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
